import {fetchActions} from "./index";
import {GET_MANUFACTURING_DATA, SET_SCHEMAS} from "../constants/Manufacturing";

export const checkStocks =
    (data) =>
        async (dispatch, getState) => {
            const res = await fetchActions.post('/api/stocks/set-availability-check', dispatch, getState, data);
            return res;
        };


export const newSchema = (schema) => async (dispatch, getState) => {
    const res = await fetchActions.post('/api/schema', dispatch, getState, schema)
    return res
}

export const getSchemas = () => async (dispatch, getState) => {
    const res = await fetchActions.get('/api/schema?type=drilling', dispatch, getState)
    if (res.status === 200) {
        dispatch({type: SET_SCHEMAS, schemas: res?.data})
    }
}

export const checkWorkerID = (workerID, numer_zamowienia) => async (dispatch, getState) => {
    const res = await fetchActions.post(`/api/manufacturing/check-worker/${workerID}`, dispatch, getState, {numer_zamowienia});
    return res
}

export const getLogs = (complete, sort, query) => async (dispatch, getState) => {
    if (complete) {
        const res = await fetchActions.get(`/api/manufacturing/custom-manufacturing${complete ? '?complete=true' : ""}&sort=created_at&order=${sort}${query ? `&${query}` : ''}`, dispatch, getState);
        return res;
    } else {
        const res = await fetchActions.get(`/api/manufacturing/custom-manufacturing?sort=created_at&order=${sort}${query ? `&${query}` : ''}`, dispatch, getState);
        return res;
    }
}

export const createCustomManufacturing = (numer_zamowienia) => async (dispatch, getState) => {
    const res = await fetchActions.post('/api/manufacturing/custom-manufacturing/create', dispatch, getState, {numer_zamowienia})
    return res
}

export const checkOrderId = (order_id) => async (dispatch, getState) => {
    const res = await fetchActions.get(`/api/manufacturing/check-order/${order_id}`, dispatch, getState)
    return res.status !== 404;
}

export const createCustomManufacturingLog = (createManufacturingLogDto, schema_id, requestType = 'post', statusId) => async (dispatch, getState) => {
    const res = await fetchActions[requestType](`/api/manufacturing/custom-manufacturing${statusId ? `/${statusId}` : ''}`, dispatch, getState, {
        schema_id,
        createManufacturingLogDto
    })
    return res
}

export const removeLog = (id) => async (dispatch, getState) => {
    return fetchActions.delete(`/api/manufacturing/${id}`, dispatch, getState);
}

export const createStandardManufacturingLog = (createStandardManufacturingLogDto) => async (dispatch, getState) => {
    const res = await fetchActions.post('/api/manufacturing/standard-manufacturing/create', dispatch, getState, createStandardManufacturingLogDto)
    return res
}

export const updateStandardManufacturingLog = (id, createManufacturingLogDto) => async (dispatch, getState) => {
    const res = await fetchActions.patch(`/api/manufacturing/standard-manufacturing/${id}`, dispatch, getState, {createManufacturingLogDto})
    return res
}

export const printManufacturingLabels = (id) => async (dispatch, getState) => {
    await fetchActions.post(`/api/manufacturing/custom-manufacturing/print`, dispatch, getState, {id})
}

export const checkFileStatus = (id) => async (dispatch, getState) => {
    return fetchActions.get(`/api/manufacturing/check-file-status/${id}`, dispatch, getState);
};

export const getStandardManufacturingLogs = (numer_pracownika) => async (dispatch, getState) => {
    return fetchActions.get(`/api/manufacturing/standard-manufacturing/${numer_pracownika}`, dispatch, getState)
}

export const deleteStandardLabel = (id, data) => async (dispatch, getState) => {
    return fetchActions.patch(`/api/manufacturing/standard-manufacturing/${id}`, dispatch, getState, {createManufacturingLogDto: data})
}

export const persistChanges = (numer_pracownika) => async (dispatch, getState) => {
    return fetchActions.post('/api/manufacturing/standard-manufacturing/persist', dispatch, getState, {numer_pracownika})
}

export const findOneLog = (numer_zamowienia) => async (dispatch, getState) => {
    return fetchActions.get(`/api/manufacturing/${numer_zamowienia}`, dispatch, getState)
}

export const checkStandardLogStatuses = (numer_pracownika, startDate, endDate) => async (dispatch, getState) => {
    return fetchActions.post('/api/manufacturing/standard-manufacturing/check', dispatch, getState, {
        numer_pracownika,
        startDate,
        endDate
    })
}

export const getFilters = (complete) => async (dispatch, getState) => {
    return fetchActions.get(`/api/manufacturing/filters${complete ? '?complete=complete' : ""}`, dispatch, getState)
}

export const updateCustmLabel = (createManufacturingLogDto) => async (dispatch, getState) => {
    return fetchActions.patch('/api/manufacturing/custom-manufacturing/label-update', dispatch, getState, {createManufacturingLogDto});
}

export const deleteCustmLabel = (createManufacturingLogDto) => async (dispatch, getState) => {
    return fetchActions.patch('/api/manufacturing/custom-manufacturing/label-delete', dispatch, getState, {createManufacturingLogDto});
}

export const closeCustomLogsBatch = (ids) => async (dispatch, getState) => {
    return fetchActions.patch('/api/manufacturing/custom-manufacturing/close-batch', dispatch, getState, {ids});
}

export const deleteSchema = (id) => async (dispatch, getState) => {
    return fetchActions.delete(`/api/schema/${id}`, dispatch, getState)
}

export const getManufacturingData = (limit = 10, page = 1, search) => async (dispatch, getState) => {
    const res = await fetchActions.get(`/api/manufacturing-data/data?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`, dispatch, getState)
    const {count, data: manufacturing_data} = res?.data || {}
    dispatch({
        type: GET_MANUFACTURING_DATA,
        totalItems: count,
        manufacturing_data
    })
}

export const addManufacturingData = (data) => async (dispatch, getState) => {
    return fetchActions.post(`/api/manufacturing-data`, dispatch, getState, data)
}