import {SET_SCHEMAS, GET_MANUFACTURING_DATA} from "../constants/Manufacturing";
import {SIGNOUT} from "../constants/Auth";

const initialState = {
    schemas: [],
    totalItems: 0,
    manufacturing_data: []
}

const manufacturing = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCHEMAS:
            return {
                ...state,
                schemas: action.schemas
            }
        case GET_MANUFACTURING_DATA:
            return {
                ...state,
                totalItems: action.totalItems,
                manufacturing_data: action.manufacturing_data,
            }
        case SIGNOUT:
            return initialState;
        default:
            return state
    }
}

export default manufacturing;