import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Loading from "components/shared-components/Loading";
import {APP_PREFIX_PATH} from "configs/AppConfig";
import {connect} from "react-redux";

export const AppViews = ({role}) => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                {+role > 1 && (
                    <Route
                        path={`${APP_PREFIX_PATH}/packing`}
                        component={lazy(() => import(`./packing`))}
                    />
                )}
                {+role > 2 && (
                    <Route
                        path={`${APP_PREFIX_PATH}/label-printing`}
                        component={lazy(() => import(`./label-printing`))}
                    />
                )}
                <Route
                    path={`${APP_PREFIX_PATH}/inventories`}
                    component={lazy(() => import(`./inventories`))}
                />
                {+role > 3 && (
                    <Route
                        path={`${APP_PREFIX_PATH}/order-history`}
                        component={lazy(() => import("./history"))}
                    />
                )}
                {role > 3 && (
                    <Route
                        path={`${APP_PREFIX_PATH}/users`}
                        component={lazy(() => import("./users"))}
                    />
                )}
                {+role > 1 && (
                    <Route
                        path={`${APP_PREFIX_PATH}/manufacturing/:id`}
                        component={lazy(() => import(`./manufacturing`))}
                    />
                )}
                {+role > 1 && (
                    <Route
                        path={`${APP_PREFIX_PATH}/manufacturing`}
                        component={lazy(() => import(`./manufacturing`))}
                    />
                )}
                {+role > 3 ? (<Route path={`${APP_PREFIX_PATH}/manufacturing-data`}
                                     component={lazy(() => import('./manufacturing-data'))}/>) : null}
                {+role >= 2 ? (
                    <Redirect
                        from={`${APP_PREFIX_PATH}`}
                        to={`${APP_PREFIX_PATH}/packing`}
                    />
                ) : (
                    <Redirect
                        from={`${APP_PREFIX_PATH}`}
                        to={`${APP_PREFIX_PATH}/inventories`}
                    />
                )}
            </Switch>
        </Suspense>
    );
};

const mapStateToProps = ({Auth}) => {
    const {role} = Auth;
    return {role};
};

export default React.memo(connect(mapStateToProps)(AppViews));
